<template>
  <v-dialog
    scrollable
    max-width="700"
    persistent
    :value="true"
  >
    <v-card
      v-if="isLoading"
      class="pa-4"
    >
      <v-progress-linear
        indeterminate
        color="primary"
      />
      <h6 class="text-h6 text-center">Carregando...</h6>
    </v-card>
    <v-card v-else>
      <v-card-text>
        <CategoriesForm
          :category="category"
          @save="updateCategory"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    CategoriesForm: () => import('./CategoriesForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    category: {},
    askToLeave: true,
    isLoading: true,
  }),
  methods: {
    getCurrentCategory() {
      this.$api
        .get(`product_categories/${this.$route.params.id}`)
        .then((res) => {
          this.category = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    updateCategory() {
      this.$api
        .patch('product_categories', {
          name: this.category.name,
          id: this.$route.params.id,
        })
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Categoria atualizada com sucesso',
            confirm: () => {
              this.$router.push({ name: 'categories' })
              this.$emit('updateList')
            },
          })
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops! Falha ao tentar atualizar esta categoria',
            caption: 'Verifique se os campos estão preenchidos corretamente.',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
  },
  created() {
    this.getCurrentCategory()
  },
}
</script>
